// src/components/ChangePasswordForm.js
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../assets/styles/ChangePasswordForm.css';
import { axiosInstance } from '../axiosConfig';
import { API_ROUTES } from '../Config';

const ChangePasswordForm = () => {
  console.log('llegue al change password');
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Ingrese su contraseña actual'),
    newPassword: Yup.string()
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
      .max(10, 'La contraseña no debe tener más de 10 caracteres')
      .matches(/^[a-zA-Z0-9*_]+$/, 'La contraseña solo puede contener letras, números, * y _')
      .required('Ingrese su nueva contraseña'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
      .required('Confirme su nueva contraseña'),
  });

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const response =await axiosInstance.post(`${API_ROUTES.users}/change-password`,{
        current_password: values.currentPassword,
        new_password: values.newPassword,
      });

      if (response.status === 200) {
        
        setAlert({ message: response.data.message, type: 'success' });
      } else {
        
        setAlert({ message: response.data.message, type: 'error' });
      }
    } catch (error) {
      setAlert({ message: error.response?.data?.message  || 'Error al cambiar la contraseña',type:'error' });

    } finally {
      setSubmitting(false);
    }
  };

  const [alert, setAlert] = useState({ message: '', type: '' });

  const closeAlert = () => {
    setAlert({ message: '', type: '' });
  };

  return (
    <div className="change-password-form">
      <div className="change-password-content">
        <h2>Cambiar la Contrase&ntilde;a</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="currentPassword">Contraseña Actual</label>
                <Field
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Ingrese su contraseña actual"
                />
                <ErrorMessage name="currentPassword" component="div" className="field-error" />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">Nueva Contraseña</label>
                <Field
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Ingrese su nueva contraseña"
                />
                <ErrorMessage name="newPassword" component="div" className="field-error" />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar Nueva Contraseña</label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirme su nueva contraseña"
                />
                <ErrorMessage name="confirmPassword" component="div" className="field-error" />
              </div>
              <div className="form-actions">
                <button type="submit" className="btn-submit" disabled={isSubmitting}>
                  Grabar
                </button>
              </div>
              {alert.message && (
                <div className={`status-message ${alert.type}`}>
                  {alert.message}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePasswordForm;

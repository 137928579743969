import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../axiosConfig'; // Asegúrate de tener tu configuración de axios
import { API_ROUTES } from '../../Config';

const ClientDropdown = ({ selectedClient, onSelectClient, userId }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true); // Comenzar cargando
      setError(null); // Reiniciar cualquier error previo
      try {
        console.log("Fetching clients...");
        const response = await axiosInstance.post(API_ROUTES.clientes + '/lista', { id_user: userId });
        if (response.status === 200) {
          console.log("Clients fetched successfully:", response.data.data);
          setClients(response.data.data);
        } else {
          console.error("Error fetching clients: ", response);
          setError('Error al cargar los clientes');
        }
      } catch (err) {
        console.error("Error in fetchClients:", err);
        setError('Error al cargar los clientes');
      } finally {
        setLoading(false); // Finalizar cargando
      }
    };

    if (userId) {
      fetchClients();
    }
  }, [userId]);

  if (loading) {
    return <p>Cargando clientes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="clientes-dropdown">
      <label htmlFor="clientes">Clientes:</label>
      <select id="idcliente" value={selectedClient} onChange={(e) => onSelectClient(e.target.value)}>
        <option value="">Seleccione un cliente</option>
        {clients.map(client => (
          <option key={client.id_cliente} value={client.id_cliente}>{client.razon_social}</option>
        ))}
      </select>
    </div>
  );
};

export default ClientDropdown;

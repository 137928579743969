import React from 'react';

const TextInput = ({ label, name, value, onChange }) => (
  <div className="form-group">
    <label>{label}</label>
    <input type="text" name={name} value={value} onChange={onChange} className="form-control" />
    
  </div>
);

export default TextInput;
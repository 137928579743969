import React, { useState, useEffect,useContext } from 'react';
import { axiosInstance } from '../../axiosConfig';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TextInput from './TextInput';
import Dropdown from './Dropdown';
import ClientesSection from './ClientesSection';
import '../../assets/styles/CreateUserPage.css';
import { API_ROUTES } from '../../Config';
import { AuthProvider, useAuth }  from '../../AuthContext';


const CreateUser = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const { id_user } = useParams();

  const { idUser, name, token, logout } =useAuth();// useContext(AuthContext);

  const [showClientes, setShowClientes] = useState(false);
  const [IdUser, setIdUser] =useState(); //id_user cuando se va actualziar
  const [IdSesion, setIdSesion] =useState(idUser); 

 
  
  const [formData, setFormData] = useState({
    id_user: '',
    id_session: IdSesion,
    nombre: '',
    correo: '',
    cargo: '',
    departamento: '',
    rol: '',
    gestionaIndicadores: false,
    clientes: []
  });

  const [options, setOptions] = useState({
    cargos: [],
    departamentos: [],
    roles: []
  });

  const [alert, setAlert] = useState({ message: '', type: '' });
  const [loadingError, setLoadingError] = useState(false); 

  
  useEffect(() => {
    // Aquí puedes utilizar idUserSession y name    
    console.log('Id de sesión del usuario:', idUser);
    setIdSesion(idUser);
   
  }, [idUser, name]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [cargosRes, departamentosRes, rolesRes] = await Promise.all([
          axiosInstance.get(`${API_ROUTES.cargos}/cargos`),
          axiosInstance.get(`${API_ROUTES.departamentos}/departamentos`),
          axiosInstance.get(`${API_ROUTES.roles}/perfil`)
        ]);

        if (departamentosRes.status === 200) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            departamentos: departamentosRes.data
          }));
        } else {
          console.error('Error al obtener departamentos:', departamentosRes.statusText);
        }

        if (cargosRes.status === 200) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            cargos: cargosRes.data
          }));
        } else {
          console.error('Error al obtener cargos:', cargosRes.statusText);
        }

        if (rolesRes.status === 200) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            roles: rolesRes.data
          }));
        } else {
          console.error('Error al obtener roles:', rolesRes.statusText);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();

    const loadUserData = (userData) => {
      if (userData) {
     // Asegúrate de que los datos del usuario estén aquí
      setFormData({
        id_session:IdSesion,
          id_user: userData.user.id_user || '',
          nombre: userData.user.name || '',
          correo: userData.user.email || '',
          cargo: userData.user.id_cargo || '',
          departamento: userData.user.id_departamento || '',
          rol: userData.perfil_ids[0] || '',
          gestionaIndicadores: userData.user.gestiona_indicadores || false,
          clientes: userData.cliente_ids || []
      });
    //  console.log('Datos del userData if (userData):', userData.user.gestionaIndicadores);
      if (userData.cliente_ids && userData.cliente_ids.length > 0) {
        setShowClientes(true);
      } else {
        setShowClientes(false);
      }
      setLoadingError(false);
    } else {
      setLoadingError(true);
    }
    };
   
    if (location.state && location.state.userData) {
      loadUserData(location.state.userData);
      setIdUser(location.state.userData.user.id_user);
      //console.log('Dato location.state.userData:', IdUser);
      //console.log('Dato location.state.userData:', location.state.userData);
    } else if (IdUser) {
     
      const fetchUserData = async () => {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.users}/getusuario`, {
            id_user: IdUser
          });
         // console.log('retorno id id_user:', response.user);
          //const response = await axiosInstance.get(`${API_ROUTES.usuarios}/${id_user}`);
          if (response.status === 200) {
            loadUserData(response.data.data);

          } else {
            console.error('Error al obtener los datos del usuario:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, [id_user, location.state]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleClientesChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setFormData({
      ...formData,
       clientes: selectedValues
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { nombre, correo, cargo, departamento, rol, clientes,id_user,id_session } = formData;

    // Validar campos...
    // ...

    try {
      let response;
      //if (id_user) {
      if (IdUser) {
        response = await axiosInstance.post(`${API_ROUTES.users}/updateusuario`, formData);
      } else {
        response = await axiosInstance.post(`${API_ROUTES.usuarios}/register`, formData);
      }

      if (response.status === 200 || response.status === 201) {
        setAlert({ message: `Usuario ${id_user ? 'actualizado' : 'creado'} exitosamente`, type: 'success' });
        if (!id_user) {
          setFormData({
            id_user: '',
            id_session:IdSesion,
            nombre: '',
            correo: '',
            cargo: '',
            departamento: '',
            rol: '',
            gestionaIndicadores: false,
            clientes: []
          });
          setShowClientes(false); // Ocultar sección clientes
        }
      } else {
        console.error('Unexpected response status:', response.status);
        setAlert({ message: 'Error al crear o actualizar el usuario', type: 'error' });
      }
    } catch (error) {
      console.error('Error creating or updating user:', error);
      setAlert({ message: 'Error al crear o actualizar el usuario', type: 'error' });
    }
  };

  const handleCancel = () => {
    navigate('/main/users');
  };

  const handleGestionaIndicadoresChange = (event) => {
    setShowClientes(event.target.checked);
    setFormData({
      ...formData,
    
      gestionaIndicadores: event.target.checked,
      clientes: event.target.checked ? [] : formData.clientes
    });
  };

  const closeAlert = () => {
    setAlert({ message: '', type: '' });
  };

 
  return (
    <div className="container">
      <h1>{id_user ? 'Editar Usuario' : 'Crear Usuario'}</h1>
      {alert.message && (
        <div className={`alert ${alert.type === 'success' ? 'alert-success' : 'alert-error'}`} onClick={closeAlert}>
          {alert.message}
          <span className="close" onClick={closeAlert}>&times;</span>
        </div>
      )}
       {loadingError ? (
        <div className="alert alert-error">
          Error al cargar los datos del usuario.
        </div>
      ) : (
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id_user" value={IdUser} />
        <input type="hidden" name="id_session" value={IdSesion} />
        <div className="row">
          <div className="col">
            <TextInput label="Nombre Completo*" name="nombre" value={formData.nombre} onChange={handleInputChange} />
          </div>
          <div className="col">
            <TextInput label="Correo*" name="correo" value={formData.correo} onChange={handleInputChange} />
          </div>
        </div>

        <Dropdown label="Cargo *" name="cargo" options={options.cargos} value={formData.cargo} onChange={handleInputChange} idKey="id_cargos" />
        <Dropdown label="Departamento *" name="departamento" options={options.departamentos} value={formData.departamento} onChange={handleInputChange} idKey="id_departamentos" />
        <Dropdown label="Rol *" name="rol" options={options.roles} value={formData.rol} onChange={handleInputChange} idKey="id_perfil" />

        <div className="form-group radio-button-group">
          <label>Gestiona Indicadores</label>
          <input type="checkbox" name="gestionaIndicadores" checked={formData.gestionaIndicadores} onChange={handleGestionaIndicadoresChange} />
        </div>
        {showClientes && <ClientesSection name="clientes" selectedClientes={formData.clientes} onChange={handleClientesChange} />}
        <div className="row">
          <button type="submit">{id_user ? 'Actualizar Usuario' : 'Crear Usuario'}</button>
          <button type="button" onClick={handleCancel}>Cancelar</button>
        </div>
      </form>  )}
    </div>
  );
};

export default CreateUser;

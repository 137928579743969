
import React,{useContext} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage'; // Importa el componente LoginPage
import MainPage from './pages/MainPage';
import CreateUserPage from './pages/CreateUserPage';
import UserManagementPage from './pages/UsersPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import CreateRolePage from './pages/CreateRolePage';
import CreateIndicatorsPage from './pages/CreateIndicatorsPage';
import ReportIndicatorsPage from './pages/ReportIndicadoresPage';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
//import { AuthProvider, AuthContext } from './AuthContext';
import { AuthProvider} from './AuthContext';




function App() {
  return (
    
          <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                     <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                  
                   {/* Rutas protegidas */}
                   <Route path="/main/*" element={<ProtectedRoute element={<MainPage />} />}>
                    <Route index element={<MainPage />} />
                    <Route path="users" element={<UserManagementPage />} />
                    <Route path="create-user" element={<CreateUserPage />} />
                    <Route path="change-password" element={<ChangePasswordPage />} />
                    <Route path="create-role" element={<CreateRolePage />} />
                    <Route path="create-indicators" element={<CreateIndicatorsPage />} />
                    <Route path="report-indicators" element={<ReportIndicatorsPage />} />
                    <Route path="about" element={<About />} />
                  </Route>
          
                   
                </Routes>
            </AuthProvider>
          </Router>
    
  );
}

export default App;

import React, { useState, useEffect } from 'react';

const PeriodDropdown = ({ selectedPeriod, onSelectPeriod }) => {
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // Crear un array de objetos para los meses del año actual y los próximos 12 meses
    const monthsOfYear = [];
    for (let i = 0; i < 12; i++) {
      const monthValue = `${currentYear}-${i + 1}`;
      const monthLabel = `${monthNames[i]} ${currentYear}`;
      monthsOfYear.push({ value: monthValue, label: monthLabel });
    }

    setMonths(monthsOfYear);

    // Establecer el período por defecto si no hay ningún período seleccionado
    if (!selectedPeriod) {
      const currentMonth = new Date().getMonth() + 1; // +1 porque getMonth() devuelve de 0 a 11
      const defaultPeriod = `${currentYear}-${currentMonth}`;
      onSelectPeriod(defaultPeriod);
    }
  }, [selectedPeriod, onSelectPeriod]);

  const handleChange = (event) => {
    onSelectPeriod(event.target.value);
  };

  return (
    <div className="period-dropdown">
      <label htmlFor="periodo">Selecciona un periodo:</label>
      <select id="periodo" value={selectedPeriod} onChange={handleChange}>
        <option value="">Selecciona un mes...</option>
        {months.map(month => (
          <option key={month.value} value={month.value}>{month.label}</option>
        ))}
      </select>
    </div>
  );
};

export default PeriodDropdown;

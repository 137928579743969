import React, { createContext, useState, useEffect,useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [name, setName] = useState(null);
    const [token, setToken] = useState(null);
    const [idUser, setIdUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Estado para indicar carga
    const [error, setError] = useState(null); // Estado para manejar errores


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const savedName = localStorage.getItem('name');
                const savedIdUser = localStorage.getItem('id_user');
                const savedToken = localStorage.getItem('token');
                
                // Simula un retraso para ver la pantalla de carga
                await new Promise(resolve => setTimeout(resolve, 1000));

                if (savedName && savedToken && savedIdUser) {
                    setName(savedName);
                    setIdUser(savedIdUser);
                    setToken(savedToken);
                } else {
                    setError('No se encontraron datos de sesión en localStorage.');
                }
            } catch (error) {
                setError('Error al cargar datos de sesión desde localStorage.');
                console.error('Error fetching user session data:', error);
            } finally {
                setLoading(false); // Finaliza la carga
            }
        };

        fetchUserData();
    }, []);

    const login = (id, token, name) => {
        setName(name);
        setToken(token);
        setIdUser(id);

        //localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('id_user', id);
        localStorage.setItem('token', token);
        localStorage.setItem('name', name);

        setIsAuthenticated(true);
    };

    const logout = () => {
        setName(null);
        setToken(null);
        setIdUser(null);

        localStorage.removeItem('name');
        localStorage.removeItem('token');
        localStorage.removeItem('id_user');

        setIsAuthenticated(false);
    };

    useEffect(() => {
        // Verifica el estado de autenticación, por ejemplo, revisando un token en el localStorage
        const token = localStorage.getItem('token');
        if (token) {
          setIsAuthenticated(true);
        }
      }, []);
     // console.log('en authcontest:'+isAuthenticated);
      // Muestra un mensaje de carga mientras se cargan los datos
      if (loading) {
        return <div>Cargando datos...</div>;
    }

    // Manejo de errores
  /*  if (error) {
        return <div>Error: {error}</div>;
    }*/
    
    return (
        <AuthContext.Provider value={{ idUser, token,name, login, logout, isAuthenticated  }}>
            {children}
        </AuthContext.Provider>
    );
};

//export default AuthContext;
export const useAuth = () => {
    return useContext(AuthContext);
  };
  
export default AuthContext; 

import React from 'react';

const IndicatorTable = ({ data }) => {

    const getIndicatorColor = (difference) => {
        if (difference >= 0) {
            return '#00FF00'; // Verde
        } else if (difference >= -5) {
            return '#FFFF00'; // Amarillo
        } else if (difference >= -10) {
            return '#FFA500'; // Naranja
        } else {
            return '#FF0000'; // Rojo
        }
    };

    const openEvidenceFile = (fileUrl) => {
        window.open(fileUrl, '_blank', 'noopener,noreferrer'); // Abrir el archivo en una nueva pestaña
    };

    const getFileURL = (relativePath) => {
        return `${process.env.REACT_APP_BASE_URL}/storage/${relativePath}`;
    };

    return (
        <div className="indicator-table">
            <table className="no-border">
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Perspectiva</th>
                        <th>Categoría</th>
                        <th>Indicador</th>
                        <th>Valor Real</th>
                        <th>Valor Meta</th>
                        <th>Diferencia</th>
                        <th>Semáforo</th>
                        <th>Enlace a la Evidencia</th>
                        <th>Responsable</th>
                    </tr>
                </thead>{}
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.cliente}</td>
                            <td>{item.perspectiva}</td>
                            <td>{item.categoria}</td>
                            <td>{item.indicador}</td>
                            <td>{item.valor_real}</td>
                            <td>{item.valor_meta}</td>
                            <td>{item.diferencia}</td>
                            <td style={{
                                textAlign: 'center',
                                padding: '0', // Sin relleno adicional
                                borderRadius: '50%', // Hace que el semáforo sea circular
                                width: '30px', // Diámetro del círculo
                                height: '30px', // Diámetro del círculo
                                backgroundColor: getIndicatorColor(item.diferencia),
                                color: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                {/* No es necesario el texto, solo el círculo */}
                            </td>
                            <td>{item.nombre_archivo ?
                                    <a href={getFileURL(item.ubicacion_archivo)} target="_blank" rel="noopener noreferrer" onClick={() => openEvidenceFile(item.ubicacion)}>
                                        Ver Archivo
                                       
                                    </a>
                                    :
                                    "Sin Archivo"
                                }</td>
                            <td>{item.responsable}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IndicatorTable;
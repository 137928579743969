import React, { useState,useContext } from 'react';
import '../assets/styles/MainPage.css';
import Header from '../components/main/Header';
import Footer from '../components/main/Footer';
import Sidebar from '../components/main/Sidebar';
import { AuthProvider, useAuth }  from '../AuthContext';
import { Routes, Route, Outlet } from 'react-router-dom';


const MainPage = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const { idUser, name, token, logout } = useAuth();//useContext(AuthContext);

    const toggleSidebar = () => {
        //setSidebarVisible(!sidebarVisible);
        setSidebarVisible(prevState => !prevState);
    };

    return (
        <div className="main-page">
            <Header toggleSidebar={toggleSidebar} sidebarVisible={sidebarVisible} nameUser={name}/>
            <div className="content">
                <Sidebar visible={sidebarVisible} id_user={idUser}/>
                 <main className={`main-content ${sidebarVisible ? '' : 'expanded'}`}>
                        <Outlet />
                </main>
            </div>
            {/*<Footer sidebarVisible={sidebarVisible} />*/}
        </div>
    );
};




export default MainPage;


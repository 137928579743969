import React, { useState } from 'react';
import '../../assets/styles/UserTableRow.css'; // Estilos CSS para las filas de usuario
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { axiosInstance } from '../../axiosConfig';
import { API_ROUTES } from '../../Config';
import { useNavigate } from 'react-router-dom';

import ConfirmationModal from '../ConfirmationModal';
import AlertModal from '../AlertModal';

const UserTableRow = ({ user }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({ title: '', message: '', type: '' });
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleViewUser = async () => {
    setIsSubmitting(true);
    try {
   
      const response = await axiosInstance.post(`${API_ROUTES.users}/getusuario`, {
        id_user: user.id_user
      });
      if (response.status === 200) {
        // console.log('response.user:',response.data);
          navigate('/main/create-user', { state: { userData: response.data } });
          
      } else {
        console.log('Error fetching user data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      
      setIsSubmitting(false);
    }
  };

  const handleDeleteUser = async () => {
    setSubmitting(true);
    try {
      const response = await axiosInstance.post(`${API_ROUTES.users}/deactive`, {
        id_user: user.id_user,
      });

      if (response.status === 200) {
        setModalConfig({
          title: 'Éxito',
          message: response.data.message,
          type: 'success',
        });
      } else {
        setModalConfig({
          title: 'Error',
          message: response.data.message,
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      setModalConfig({
        title: 'Error',
        message: 'Error al desactivar el usuario',
        type: 'error',
      });
    } finally {
      setSubmitting(false);
      setShowAlertModal(true);
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setShowConfirmationModal(false);
    handleDeleteUser();
  };

  const cancelDelete = () => {
    setShowConfirmationModal(false);
  };

  const closeAlert = () => {
    setShowAlertModal(false);
  };

  return (
    <div className="action-buttons">
      <button className="view-button" onClick={handleViewUser}>
        <FontAwesomeIcon icon={faSearch} /> 
      </button>
      <button className="delete-button" onClick={handleDeleteClick}>
        <FontAwesomeIcon icon={faTrashAlt} /> 
      </button>

      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />

      <AlertModal
        show={showAlertModal}
        message={modalConfig.message}
        onClose={closeAlert}
        type={modalConfig.type}
      />
    </div>
  );
};

export default UserTableRow;
// src/pages/ChangePasswordPage.js
import React from 'react';
import ChangePasswordForm from '../components/ChangePasswordForm';
import '../assets/styles/ChangePasswordPage.css';

const ChangePasswordPage = () => {
  return (
    <div className="change-password-page">
      <h1></h1>
      <ChangePasswordForm />
    </div>
  );
};

export default ChangePasswordPage;

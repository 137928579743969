import React, { useState, useEffect } from 'react';
import '../../assets/styles/Sidebar.css';
import logo from '../../assets/images/logo.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { axiosInstance } from '../../axiosConfig';
import { API_ROUTES } from '../../Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCog, faSignOutAlt, faChartLine } from '@fortawesome/free-solid-svg-icons';


const Sidebar = ({ visible, id_user }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axiosInstance.post(`${API_ROUTES.menu}/menus`, {
          id_user: id_user
        });

        if (response.status === 200) {
         
         if (Array.isArray(response.data.data)) {
          console.log('menus', JSON.stringify(response.data.data, null, 2)); 
          const data = response.data.data;
          const formattedMenu = formatMenuData(data);
          console.log('Datos formateados:', formattedMenu);
          setMenuItems(formattedMenu);
         } else {
          console.error('Los datos de menú no son un array');

         }
        } else {
          console.error('Error al cargar las opciones del menú');
        }
      } catch (error) {
        console.error('Error al obtener las opciones del menú:', error);
      }
    };

    fetchMenuItems();
  }, [id_user]);



  const formatMenuData = (menuData) => {
    const formattedMenu = [];

    const rootItems = menuData.filter(item => item.es_raiz === 1 && item.orden !== 0);

    rootItems.forEach(rootItem => {
      const submenu = menuData.filter(subitem => subitem.menu_padre === rootItem.id_menu && subitem.orden !== 0);

      const formattedItem = {
        name: rootItem.etiqueta,
        link: rootItem.url,
        icon: rootItem.icono,
        submenu: submenu.map(subitem => ({
          name: subitem.etiqueta,
          link: subitem.url,
          type: subitem.type
        }))
      };

      formattedMenu.push(formattedItem);
    });

    return formattedMenu;
  };

  console.log('menu:'+menuItems);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleItem = (index) => {
    let newMenuItems = [...menuItems];
    newMenuItems[index].open = !newMenuItems[index].open;
    setMenuItems(newMenuItems);
  };

  const handleNavLinkClick = (link, type) => {
    console.log('link:'+link);
    if (type === 'logout') {
      handleLogout();
    } else {
      navigate(link);
    }
  };
  

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'home':
        return faHome;
      case 'user':
        return faUser;
      case 'settings':
        return faCog;
      case 'chart-line':
          return  faChartLine;
      case 'logout':
        return faSignOutAlt;
      default:
        return null;
    }
  };

  return (
    <aside className={`sidebar ${visible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      <div className="logo">
      <img src={logo} alt="Logo" />
      </div>
      <nav>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index}>
              {item.submenu ? (
                <>
                 
                  <span
                    onClick={() => toggleItem(index)}
                    className={location.pathname.startsWith(item.link) ? 'active' : ''}
                  >
                   {item.icon && <FontAwesomeIcon icon={getIcon(item.icon)} />}  {item.name}
                  </span>
                  {item.submenu && item.open && (
                    <ul>
                      {item.submenu.map((subitem, subindex) => (
                        <li key={subindex}>
                          <NavLink
                            to={`${subitem.link}`} // Rutas dentro de /main/*
                            onClick={() => handleNavLinkClick(subitem.link, subitem.type)}
                            className={({ isActive }) => (isActive ? 'active-submenu' : '')} // Asegúrate de que esta clase esté definida en tu CSS
                          >
                            {subitem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <NavLink
                  to={`${item.link}`} // Rutas dentro de /main/*
                  onClick={() => handleNavLinkClick(item.link, item.type)}
                  className={({ isActive }) => (isActive ? 'active-submenu' : '')}// Asegúrate de que esta clase esté definida en tu CSS
                >
                  {item.name}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;

// pages/CreateUserPage.js
import React from 'react';
import CreateUser from '../components/usuarios/CreateUser';
import '../assets/styles/CreateUserPage.css'; // Importa tus estilos CSS aquí

const CreateUserPage = () => {
  return (
    <div>
      <CreateUser />
    </div>
  );
};

export default CreateUserPage;

// RoleCreationForm.js

import React, { useState } from 'react';
import TextInput from './TextInput'; // Asegúrate de tener este componente creado
import '../../assets/styles/RoleCreationForm.css'; // Añade estilos CSS según sea necesario

const RoleCreationForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: ''
  });
  const [alert, setAlert] = useState({ message: '', type: '' });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { nombre, descripcion } = formData;

    // Validar que el campo nombre no esté vacío
    if (!nombre) {
      setAlert({ message: 'El campo nombre es obligatorio.', type: 'error' });
      return;
    }

    // Aquí puedes realizar la lógica para enviar los datos al backend
    // Por ejemplo, puedes utilizar axios para hacer una petición POST al servidor

    // Limpia el formulario después de enviar los datos
    setFormData({
      nombre: '',
      descripcion: ''
    });

    // Muestra una alerta de éxito
    setAlert({ message: 'Perfil creado exitosamente.', type: 'success' });
  };

  const closeAlert = () => {
    setAlert({ message: '', type: '' });
  };

  return (
    <div className="role-creation-container">
      <h2>Crear Nuevo Rol</h2>
      {alert.message && (
        <div className={`alert ${alert.type === 'success' ? 'alert-success' : 'alert-error'}`} onClick={closeAlert}>
          {alert.message}
          <span className="close" onClick={closeAlert}>&times;</span>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <TextInput
            label="Nombre del Perfil *"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Descripción del Perfil</label>
          <textarea
            name="descripcion"
            value={formData.descripcion}
            onChange={handleInputChange}
            rows="4"
          />
        </div>
        <button type="submit">Crear Rol</button>
      </form>
    </div>
  );
};

export default RoleCreationForm;

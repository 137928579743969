import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter } from 'react-table';
import '../../assets/styles/UserList.css'; // Estilos CSS para la lista de usuarios
import { axiosInstance } from '../../axiosConfig';
import { API_ROUTES } from '../../Config';
import UserTableRow from './UserTableRow'; // Componente para cada fila de usuario

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    departamento: '',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(`${API_ROUTES.users}/usuarios`);
      setUsers(response.data.data);
      setFilteredUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

   // Filtros locales
  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    // Actualizar los filtros
    setFilters({
      ...filters,
      [name]: value,
    });
  };

   // Aplicar filtros locales combinados
   useEffect(() => {
    const filtered = users.filter(user => {
      const nameMatch = user.name.toLowerCase().includes(filters.name.toLowerCase());
      const departamentoMatch = user.departamento.toLowerCase().includes(filters.departamento.toLowerCase());

      return nameMatch && departamentoMatch;
    });

    setFilteredUsers(filtered);
  }, [filters, users]);

  // Definir columnas y filtros
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre Completo',
        accessor: 'name',
      },
      {
        Header: 'Departamento',
        accessor: 'departamento',
      },
      {
        Header: 'Usuario',
        accessor: 'usuario',
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }) => (value === 1 ? 'Activo' : 'Inactivo'),
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => <UserTableRow user={row.original} />, // Renderizar la fila con acciones personalizadas
      },
    ],
    []
  );

  // Configurar la tabla con react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredUsers,
      initialState: { pageIndex: 0, pageSize: 10 }, // Estado inicial de paginación y tamaño de página
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  return (
    <div className="user-list">
      <h2></h2>
      <div className="filters">
        <input
          type="text"
          name="name"
           className="form-control form-control-sm mb-2 mr-sm-2"
          placeholder="Filtrar por Nombre Completo"
          onChange={handleFilterChange}
        />
        <input
          type="text"
          name="departamento"
           className="form-control form-control-sm mb-2 mr-sm-2"
          placeholder="Filtrar por Departamento"
          onChange={handleFilterChange}
        />
      </div>
      <table {...getTableProps()} className="user-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th style={{ backgroundColor: '#0000FF', color: 'white', textAlign: 'center' }} {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="pagination-button">
          Anterior
        </button>
        <span>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage} className="pagination-button">
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default UserList;

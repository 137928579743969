import axios from 'axios';
import { API_ROUTES } from './Config';

const instance = axios.create({                                                                                                                                                                                     
  //baseURL: 'http://127.0.0.1:8000', // Coloca aquí la URL base de tu API en Laravel
  baseURL: API_ROUTES, // Coloca aquí la URL base de tu API en Laravel
  timeout: 5000, // Tiempo máximo de espera para las peticiones (opcional)
  headers: {
                                                    
    'Content-Type': 'application/json',
   /* 'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type',*/
    // Puedes agregar aquí otros encabezados comunes necesarios para tu API
  },
});

axios.interceptors.request.use(
  config => {
      const token = localStorage.getItem('token');
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);

export const axiosInstance = instance;
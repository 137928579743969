import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ajusta la ruta según tu estructura de archivos

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth(); // Obtén el estado de autenticación desde el contexto

  // Verifica si el usuario está autenticado
  if (!isAuthenticated) {
    console.log('isuatenticaded:'+isAuthenticated);
    // Si no está autenticado, redirige al inicio de sesión o a una página principal
    return <Navigate to="/" replace />;
  }
  //console.log('isuatenticaded fuera del if:'+isAuthenticated);
  //console.log('children:'+element);
  // Si el usuario está autenticado, renderiza el contenido del componente
  return <>{element}</>;
  //return <Route {...rest} element={element} />;
};

export default ProtectedRoute;

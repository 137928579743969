import React from 'react';
import '../assets/styles/Modal.css';

const ConfirmationModal = ({ show, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Confirmación</h2>
        <p>¿Está seguro de que desea inactivar al usuario?</p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="btn-confirm">Confirmar</button>
          <button onClick={onCancel} className="btn-cancel">Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

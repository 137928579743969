// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter as Router } from 'react-router-dom'; // Importa BrowserRouter si estás usando React Router v6
import App from './App'; // Importa tu componente principal App
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

/*ReactDOM.render(
  <React.StrictMode>
   
      <App />
    
  </React.StrictMode>,
  document.getElementById('root')
);*/


const root = createRoot(document.getElementById('root'));
root.render(<App />);
reportWebVitals();
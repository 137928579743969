import React from 'react';
import { Link } from 'react-router-dom';
import UserList from '../components/usuarios/UserList';
import '../assets/styles/UsersPage.css'; 

const UsersPage = () => {
  return (
    <div className="users-page">
      <h1>Usuarios Activos</h1>
      <Link to="/main/create-user" className="btn btn-green">Crear Usuario</Link>
      <div className="user-list-container">
        <UserList />
      </div>
    </div>
  );
};

export default UsersPage;

import React, { useState } from 'react';
import '../assets/styles/ReportIndicadoresPage.css';
import FilterBox from '../components/indicadores/reporte/FilterBox';
import IndicatorTable from '../components/indicadores/reporte/IndicatorTable';
import { API_ROUTES } from '../Config';
import { axiosInstance } from '../axiosConfig';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useAuth } from '../AuthContext';

const ReportIndicadoresPage = () => {
    const { idUser, name } = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (filters) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.post(API_ROUTES.indicadores + '/rptindicadores', filters);
            setData(response.data.data);
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    const handleFilterSubmit = (filters) => {
        fetchData(filters);
    };

    const getIndicatorColor = (difference) => {
        if (difference >= 0) {
            return 'Verde'; // Puede ser un ícono verde
        } else if (difference >= -5) {
            return 'Amarillo'; // Puede ser un ícono amarillo
        } else if (difference >= -10) {
            return 'Naranja'; // Puede ser un ícono naranja
        } else {
            return 'Rojo'; // Puede ser un ícono rojo
        }
    };

    const downloadExcel = async () => {
        try {
            const formattedData = data.map(item => ({
                Cliente: item.cliente,
                Periodo: item.periodo,
                Perspectiva: item.perspectiva,
                Categoría: item.categoria,
                Servicio: item.servicio,
                Indicador: item.indicador,
                'Valor Real': item.valor_real,
                'Valor Meta': item.valor_meta,
                Diferencia: item.diferencia,
                Responsable: item.responsable,
            }));

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet([]);

            // Añadir cabecera personalizada al inicio del archivo Excel
            XLSX.utils.sheet_add_aoa(ws, [
                ['Reporte de Indicadores de Desempeño'],
                [`Fecha de Generación: ${new Date().toLocaleString()}`],
                [`Usuario: ${name}`], // Reemplaza 'Nombre del Usuario' con el nombre real del usuario
                [], // Fila vacía
                ['Cliente', 'Periodo', 'Perspectiva', 'Categoría', 'Servicio', 'Indicador', 'Valor Real', 'Valor Meta', 'Diferencia', 'Responsable']
            ], { origin: 'A1' });

            // Añadir los datos formateados después de la cabecera
            XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'A6', skipHeader: true });

            // Aplicar estilos a las celdas de Semáforo según el color determinado
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let R = range.s.r + 6; R <= range.e.r; ++R) { // Comienza desde la fila 7
                const cell_address = XLSX.utils.encode_cell({ r: R, c: 6 }); // Columna de Semáforo
                if (ws[cell_address]) {
                    const color = ws[cell_address].v;
                    let fillColor = 'FFFFFF'; // Color blanco por defecto para el texto
                    switch (color) {
                        case 'Verde':
                            fillColor = '00FF00'; // Verde
                            break;
                        case 'Amarillo':
                            fillColor = 'FFFF00'; // Amarillo
                            break;
                        case 'Naranja':
                            fillColor = 'FFA500'; // Naranja
                            break;
                        case 'Rojo':
                            fillColor = 'FF0000'; // Rojo
                            break;
                        default:
                            fillColor = 'FFFFFF'; // Blanco si no hay color definido
                            break;
                    }
                    ws[cell_address].s = {
                        fill: { bgColor: { rgb: fillColor } },
                        font: { color: { rgb: 'FFFFFFFF' } }, // Texto blanco
                        alignment: { horizontal: 'center' }, // Alineación centrada
                        border: {
                            top: { style: 'thin', color: { rgb: '00000000' } },
                            bottom: { style: 'thin', color: { rgb: '00000000' } },
                            left: { style: 'thin', color: { rgb: '00000000' } },
                            right: { style: 'thin', color: { rgb: '00000000' } }
                        } // Bordes delgados sin color
                    };
                }
            }

            XLSX.utils.book_append_sheet(wb, ws, 'Indicadores');

            // Generar un Blob a partir del libro de trabajo (Workbook)
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Descargar el archivo Excel
            saveAs(blob, 'indicadores.xlsx');

            // Limpiar cualquier mensaje de error previo
            setError(null);

        } catch (error) {
            console.error('Error generating Excel file: ', error);
            setError('Error generating Excel file');
        }
    };

    // Función para convertir una cadena binaria a un arreglo de bytes
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    return (
        <div className="report-indicadores-page">
            <div className="filter-container">
                <h2 className="filter-title">Filtros de Búsqueda</h2>
                <FilterBox onSubmit={handleFilterSubmit} />
                <button className="download-button" onClick={downloadExcel}>
                    Descargar Archivo Excel
                </button>
            </div>
            <div  className="result-container">
                {loading && <p className="loading-message">Cargando...</p>}
                {error && <p className="error-message">Error: {error}</p>}
                {!loading && !error && data.length === 0 && (
                    <p className="no-data-message">No hay datos disponibles para los filtros seleccionados.</p>
                )}
                {!loading && !error && data.length > 0 && (
                    <div >
                        <IndicatorTable data={data} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportIndicadoresPage;

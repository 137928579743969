// CreateRolePage.js

import React from 'react';
import RoleCreationForm from '../components/perfil/RoleCreationForm';

const CreateRolePage = () => {
  return (
    <div className="create-role-page">
      <RoleCreationForm />
    </div>
  );
};

export default CreateRolePage;

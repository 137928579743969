import React from 'react';

function About() {
  return (
    <div className="container">
    <h1>En Construcción</h1>
    <p>Esta página está actualmente en construcción. ¡Vuelve pronto para ver el resultado final!</p>
  </div>
  );
}

export default About;

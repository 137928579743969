import React, { useEffect, useState } from 'react';
import UserInfo from '../components/indicadores/UserInfo';
import PeriodDropdown from '../components/indicadores/PeriodDropdown';
import DataTable from '../components/indicadores/DataTable';
import ClientDropdown from '../components/indicadores/ClientDropdown';
import { useAuth } from '../AuthContext';
import '../assets/styles/CreateIndicatorsPage.css';
import { axiosInstance } from '../axiosConfig';
import { API_ROUTES } from '../Config';

const CreateIndicatorsPage = () => {
  const { idUser, name } = useAuth();
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [data, setData] = useState([]);
  const [fileInputs, setFileInputs] = useState({});
  const [fileLabels, setFileLabels] = useState({});
  const [validationMessages, setValidationMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [validationFailed, setValidationFailed] = useState(false);

  useEffect(() => {
    if (idUser && selectedClient && selectedPeriod) {
      axiosInstance.post(API_ROUTES.indicadores + '/list-indicators', { id_user: idUser, client: selectedClient, period: selectedPeriod })
        .then(response => {
          const initialData = response.data.data.map(item => ({
            id: item.id,
            servicio: item.servicio,
            indicador: item.nombre_indicador,
            frecuencia: item.frecuencia_medicion,
            tipoindicador: item.tipo_indicador,
            idtipoindicador: item.id_tipo_indicador,
            real: item.valor_real || '',
            meta: item.meta,
            resultado: item.resultado || 0,
            period: selectedPeriod,
            ubicacion:item.ubicacion_archivo,
            nombre_archivo:item.nombre_archivo,
          }));
          setData(initialData);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setError('Error al cargar los datos.');
        });
    }
  }, [idUser, selectedClient, selectedPeriod]);

  const handleSave = () => {
    setValidationMessages([]);
    setSuccessMessage(null);
    setError(null);
    setValidationFailed(false);

    if (!selectedClient) {
      setValidationMessages(prev => [...prev, 'Debe seleccionar un cliente.']);
      setValidationFailed(true);
    }

    if (!selectedPeriod) {
      setValidationMessages(prev => [...prev, 'Debe seleccionar un período.']);
      setValidationFailed(true);
    }

    const validationErrors = [];

    // Validación de cada fila
    for (let row of data) {
      if (!fileInputs[row.id]) {
        validationErrors.push(`Debe adjuntar un archivo para el indicador "${row.indicador}".`);
        setValidationFailed(true);
      }
      if (row.real === '') {
        validationErrors.push(`Debe ingresar un valor real para el indicador "${row.indicador}" que tiene un archivo adjunto.`);
        setValidationFailed(true);
      }
    }

    if (validationErrors.length > 0) {
      setValidationMessages(validationErrors);
      //return;
    }

    const formData = new FormData();
    formData.append('userId', idUser);
    formData.append('name', name);
    formData.append('period', selectedPeriod);
    formData.append('client', selectedClient);

    data.forEach(row => {
      if (row.real !== '') {
        formData.append(`real_${row.id}`, row.real);
        formData.append(`meta_${row.id}`, row.meta);
        formData.append(`resultado_${row.id}`, row.resultado);
        formData.append(`period_${row.id}`, row.period);
        formData.append(`id_tipo_indicador_${row.id}`, row.idtipoindicador);

        if (fileInputs[row.id]) {
          formData.append(`file_${row.id}`, fileInputs[row.id]);
        }
      }
    });

    console.log("FormData antes de enviar:", formData);
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    axiosInstance.post(API_ROUTES.indicadores + '/save-data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
  })
      .then(response => {
        setSuccessMessage(response.data.message);

        setData(prevData => prevData.map(row => ({
          ...row,
          real: '',
          resultado: 0,
          period: '',
        })));
        setFileInputs({});
        setFileLabels({});
        setSelectedClient('');
        setSelectedPeriod('');
      })
      .catch(error => {
        console.error('Error al guardar los datos:', error);
        setError('Error al guardar los datos.');
      });
  };

  const handleClientChange = (client) => {
    setSelectedClient(client);
    setValidationMessages([]);
    setValidationFailed(false);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    setValidationMessages([]);
    setValidationFailed(false);
  };

  const handleAlertClose = () => {
    setValidationMessages([]);
    setValidationFailed(false);
  };

  return (
    <div className="main-page">
      <h1>Registro de Indicadores</h1>
      <div className="input-container">
        {/* Aquí podrías agregar más inputs si los necesitas */}
      </div>
      <div className="dropdown-container">
        <ClientDropdown 
          selectedClient={selectedClient} 
          onSelectClient={handleClientChange}
          userId={idUser}
        />
        <PeriodDropdown 
          selectedPeriod={selectedPeriod} 
          onSelectPeriod={handlePeriodChange}
        />
        <button className="save-button" onClick={handleSave}>Guardar</button>
      </div>
      {data.length > 0 && (
            <DataTable
              data={data}
              setData={setData}
              fileInputs={fileInputs}
              setFileInputs={setFileInputs}
              fileLabels={fileLabels}
              setFileLabels={setFileLabels}
            />
      )}
      {validationMessages.length > 0 && (
        <div className="alert alert-danger" onClick={handleAlertClose}>
          {validationMessages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      )}
      {successMessage && <div className="alert alert-success" onClick={handleAlertClose}>{successMessage}</div>}
      {error && <div className="alert alert-danger" onClick={handleAlertClose}>{error}</div>}
    </div>
  );
};

export default CreateIndicatorsPage;

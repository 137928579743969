// src/components/Login.js
import React, { useState, useContext  } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import axiosInstance from '../axiosConfig'; 
import { API_ROUTES } from '../Config';
import { axiosInstance } from '../axiosConfig';

import logo from '../assets/images/logo_small.jpg'; // Importa la imagen del logo
import '../assets/styles/Login.css'; // Importa el archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
//import { useAuth, AuthContext } from '../AuthContext'; 

const Login = () => {
  const navigate = useNavigate();
  
  const [error, setError] = useState(null); // Estado local para almacenar el mensaje de error
  const { login } = useContext(AuthContext);
  //const { login } = useAuth();

  const initialValues = {
    usuario: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    usuario: Yup.string().required('El nombre de usuario es obligatorio'),
    password: Yup.string().required('La contraseña es obligatoria'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      //const response = await axiosInstance.post('auth/login', values);
      const response =await axiosInstance.post(API_ROUTES.usuarios+'/login', values);
      console.log('Login successful', response.data);

      // Redirigir a MainPage si la respuesta es exitosa
      if (response.data.status === 200) {
        login(response.data.id_user, response.data.token,response.data.name);
        navigate('/main');
      } else {
        // Mostrar mensaje de error en la interfaz
        setError('Usuario o contraseña incorrectos');
      }
    } catch (error) {
      console.error('Login error', error);
      // Mostrar mensaje de error en la interfaz
      setError('Error al iniciar sesión. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
          <img src={logo} alt="Logo pequeño" className="forgot-password-logo" />
      </div>
      <div className="login-form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
                {error && <div className="error-message field-error">{error}</div>}
              <label htmlFor="username">Nombre de Usuario</label>
              <Field type="text" name="usuario" />
              <ErrorMessage name="usuario" component="div" className="field-error"/>

              <label htmlFor="password">Contraseña</label>
              <Field type="password" name="password" />
              <ErrorMessage name="password" component="div" className="field-error"/>

              <button type="submit" disabled={isSubmitting}>
                Acceder
              </button>

              <div>
                <a href="/forgot-password">Olvidé la contraseña</a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';
import ForgotPassword from '../components/ForgotPassword';
//import Header from '../components/common/Header';

const ForgotPasswordPage = () => {
  return (
    <>
      
      <ForgotPassword />
    </>
  );
};

export default ForgotPasswordPage;
import React, { useEffect, useState } from 'react';
import { API_ROUTES } from '../../Config';
import { axiosInstance } from '../../axiosConfig';

const ClientesSection = ({ name, selectedClientes, onChange }) => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await axiosInstance.get(`${API_ROUTES.clientes}/clientes`);

        if (response.status === 200 && Array.isArray(response.data.data)) {
          setClientes(response.data.data);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClientes();
  }, []);

  return (
    <div className="form-group">
      <label>Clientes</label>
      <select name={name} multiple value={selectedClientes} onChange={onChange} className="form-control">
        {clientes.map((cliente) => (
          <option key={cliente.id_cliente} value={cliente.id_cliente}>
            {cliente.razon_social}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ClientesSection;

import React, { useState,useContext } from 'react';
import '../../assets/styles/Header.css';
import avatar from '../../assets/images/profile.png';
import DropdownMenu from './DropdownMenu';
import ToggleButton from './ToggleButton';


const Header = ({ toggleSidebar, sidebarVisible,nameUser }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <header className="header" style={{ marginLeft: sidebarVisible ? '250px' : '0' }}>
            
            <div className="header-left">
                    <button className="toggle-btn" onClick={toggleSidebar}>{sidebarVisible ? '☰' : '☰'}</button>
            </div>
            <div className="header-right" style={{ marginRight: sidebarVisible ? '250px' : '10px' }}>
                <div className="user-info">
                    <img src={avatar} alt="Avatar" className="avatar" />
                    <span className="username">{nameUser}</span>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={toggleDropdown}>▼</button>
                        {dropdownVisible && (
                            <div className="dropdown-menu">
                                <a href="#">Cambiar Contraseña</a>
                                <a href="#">Salir</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};


export default Header;

// components/Dropdown.js
import React from 'react';

const Dropdown = ({ label, name, options, value, onChange, idKey }) => {
  
  
  return (
    <div className="form-group">
      <label>{label}</label>
      <select name={name} value={value} onChange={onChange} className="form-control">
        <option value="">Seleccionar...</option>
        {Array.isArray(options.data) ? (
         
          options.data.map((option, index) => (
            <option key={index} value={option[idKey]}>{option.descripcion}</option>
          ))
        ) : (
          <option disabled>Cargando opciones...</option>
        )}
      </select>
    </div>
  );
};

export default Dropdown;

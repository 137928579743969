import React, { useState, useEffect } from 'react';
import '../../../assets/styles/FilterBox.css'; // Asegúrate de tener el archivo CSS adecuado con los estilos modificados
import { axiosInstance } from '../../../axiosConfig';
import { API_ROUTES } from '../../../Config';

const FilterBox = ({ onSubmit }) => {
    const [filters, setFilters] = useState({
        cliente: '',
        periodo: '',
        perspectiva: '',
        categoria: '',
        indicador: ''
    });

    const [clientes, setClientes] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [indicadores, setIndicadores] = useState([]);
    const [perspectivas, setPerspectivas] = useState([]);
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');

    const fetchData = async () => {
        try {
            const clientesResponse = await axiosInstance.get(API_ROUTES.clientes+'/clientes');
            const categoriasResponse = await axiosInstance.get(API_ROUTES.categorias+'/categorias');
            const indicadoresResponse = await axiosInstance.get(API_ROUTES.indicadores+'/indicadores');
            const perspectivasResponse = await axiosInstance.get(API_ROUTES.perspectivas+'/perspectivas');

            setClientes(clientesResponse.data.data);
            setCategorias(categoriasResponse.data.data);
            setIndicadores(indicadoresResponse.data.data);
            setPerspectivas(perspectivasResponse.data.data);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    useEffect(() => {
        fetchData();

        const currentYear = new Date().getFullYear();
        const monthNames = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];

        // Crear un array de objetos para los meses del año actual
        const monthsOfYear = monthNames.map((month, index) => ({
            value: `${currentYear}-${index + 1}`,
            label: `${month} ${currentYear}`
        }));

        setMonths(monthsOfYear);

        // Obtener el mes actual y año actual
        const currentMonth = new Date().getMonth() + 1; // +1 porque getMonth() devuelve de 0 a 11
        setSelectedMonth(`${currentYear}-${currentMonth}`);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(filters);
    };
//console.log('clientes'+clientes);
    return (
        <div className="filter-box">
            <form onSubmit={handleSubmit}>
                <div className="filter-row">
                    <div className="filter-column">
                        <label htmlFor="cliente">Cliente:</label>
                        <select
                            name="cliente"
                            id="cliente"
                            value={filters.cliente}
                            onChange={handleChange}
                        >
                            <option value="">Seleccione Cliente</option>
                            {clientes.map(cliente => (
                                <option key={cliente.id_cliente} value={cliente.id_cliente}>{cliente.razon_social}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-column">
                        <label htmlFor="categoria">Categoría:</label>
                        <select
                            name="categoria"
                            id="categoria"
                            value={filters.categoria}
                            onChange={handleChange}
                        >
                            <option value="">Seleccione Categoría</option>
                            {categorias.map(categoria => (
                                <option key={categoria.id_categoria} value={categoria.id_categoria}>{categoria.descripcion}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="filter-row">
                    <div className="filter-column">
                        <label htmlFor="periodo">Periodo:</label>
                        <select
                            id="periodo"
                            name="periodo"
                            value={filters.periodo}
                            onChange={handleChange}
                        >
                            <option value="">Selecciona un periodo...</option>
                            {months.map(month => (
                                <option key={month.value} value={month.value}>{month.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-column">
                        <label htmlFor="indicador">Indicador:</label>
                        <select
                            name="indicador"
                            id="indicador"
                            value={filters.indicador}
                            onChange={handleChange}
                        >
                            <option value="">Seleccione Indicador</option>
                            {indicadores.map(indicador => (
                                <option key={indicador.id_indicador} value={indicador.id_indicador}>{indicador.nombre}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="filter-row">
                    <div className="filter-column">
                        <label htmlFor="perspectiva">Perspectiva:</label>
                        <select
                            name="perspectiva"
                            id="perspectiva"
                            value={filters.perspectiva}
                            onChange={handleChange}
                        >
                            <option value="">Seleccione Perspectiva</option>
                            {perspectivas.map(perspectiva => (
                                <option key={perspectiva.id_perspectiva} value={perspectiva.id_perspectiva}>{perspectiva.descripcion}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-column button-column">
                        <button type="submit">Consultar</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterBox;

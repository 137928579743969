// src/components/ForgotPassword.js
import React from 'react';
//import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { axiosInstance } from '../axiosConfig';
import { API_ROUTES } from '../Config';
import * as Yup from 'yup';
import '../assets/styles/ForgotPassword.css'; // Importa los estilos CSS específicos para ForgotPassword
import logo from '../assets/images/logo_small.jpg'; // Importa la imagen del logo

const ForgotPassword = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Ingrese un correo electrónico válido')
      .required('Ingrese su correo electrónico'),
  });

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      // Lógica para enviar la solicitud de cambio de contraseña
      const response = await axiosInstance.post(`${API_ROUTES.users}/resetPassword`, {
        email: values.email,
      });

      console.log('Response:', response.data);
      setStatus({ success: response.data.message }); // Guardar el mensaje de éxito

    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setStatus({ error: error.response ? error.response.data.message : 'Error al enviar la solicitud' }); // Guardar el mensaje de error
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('/');
  };
  return (
    <div className="forgot-password-container">
      <div className="forgot-password-content">
        <img src={logo} alt="Logo pequeño" className="forgot-password-logo" />
        <h2>¿Olvidaste tu contraseña?</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email"></label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Ingrese su correo electrónico para generar nueva contraseña"
                />
                <ErrorMessage name="email" component="div" className="field-error" />
              </div>
              <div className="form-actions">
                <button type="submit" className="btn-generate" disabled={isSubmitting}>
                  Generar Contraseña
                </button>
                
                <button type="button" className="btn-cancel" onClick={handleCancel}>
                  Cancelar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;

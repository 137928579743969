import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
//import '../assets/styles/DataTable.css';

const DataTable = ({ data, setData, fileInputs, setFileInputs, fileLabels, setFileLabels }) => {
  const calculateDifference = (real, meta) => {
    return meta - real;
  };

  const handleInputChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  const handleFileChange = (id, event) => {
    const file = event.target.files[0];
    if (file) {
      setFileInputs(prevState => ({
        ...prevState,
        [id]: file
      }));

      setFileLabels(prevState => ({
        ...prevState,
        [id]: file.name
      }));

      console.log(`Archivo seleccionado para indicador ${id}:`, file);
    }
  };

  const handleViewFile = (ubicacion) => {
    window.open(ubicacion, '_blank');
  };

  const getFileURL = (relativePath) => {
    return `${process.env.REACT_APP_BASE_URL}/storage/${relativePath}`;
};
  return (
    <div >
      <table>
        <thead>
          <tr>
            <th>Servicio</th>
            <th>Indicador</th>
            <th>Frecuencia</th>
            <th>Real</th>
            <th>Meta</th>
            <th>Diferencia</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row.id}>
              <td>{row.servicio}
                 <input type="hidden" 
                        id={`id_tipo_indicador_${row.id}`}
                        name={`id_tipo_indicador_${row.id}`}
                        value={row.idtipoindicador} />

              </td>
              <td>{row.indicador}</td>
              <td>{row.frecuencia}</td>
              <td>
                <input
                  type="text"
                  id={`real_${row.id}`}
                  name={`real_${row.id}`}
                  style={{ width: '50px', padding: '4px', boxSizing: 'border-box' }}
                  value={row.real}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    handleInputChange(row.id, 'real', newValue);
                    const metaValue = parseFloat(row.meta);
                    const difference = calculateDifference(parseFloat(newValue), metaValue);
                    handleInputChange(row.id, 'resultado', difference);
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  id={`meta_${row.id}`}
                  name={`meta_${row.id}`}
                  style={{ width: '50px', padding: '4px', boxSizing: 'border-box' }}
                  value={row.meta}
                  readOnly
                />
              </td>
              <td>
                <input
                  type="text"
                  id={`resultado_${row.id}`}
                  name={`resultado_${row.id}`}
                  style={{ width: '50px', padding: '4px', boxSizing: 'border-box' }}
                  value={row.resultado}
                  readOnly
                />
              </td>
              <td>
                <label className="upload-button">
                  {fileLabels[row.id] || 'Subir Archivo'}
                  <input
                    type="file"
                    id={`file_${row.id}`}
                    name={`file_${row.id}`}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(row.id, e)}
                  />
                </label>

                  {row.ubicacion && (
                   

                    <a href={getFileURL(row.ubicacion)} target="_blank" rel="noopener noreferrer" 
                            onClick={() => handleViewFile(row.ubicacion)}>
                                        <FontAwesomeIcon icon={faEye} />
                                       
                                    </a>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;

//const API_URL = 'http://127.0.0.1:8000/api'; // Cambia esta URL según la configuración de tu servidor Laravel
const API_URL = 'https://servicios.mejorasprocesos.com/api';
//const API_URL = 'http://servicios.ilum.com.ec/api';

export const API_ROUTES = {
  cargos: `${API_URL}/cargos`,
  departamentos: `${API_URL}/depto`,
  roles: `${API_URL}/perfil`,
  usuarios: `${API_URL}/auth`,
  users: `${API_URL}/usuarios`,
  clientes: `${API_URL}/clientes`,
  menu: `${API_URL}/menu`,
  indicadores: `${API_URL}/indicadores`,
  perspectivas: `${API_URL}/perspectivas`,
  categorias: `${API_URL}/categorias`,
};